
























































































import Vue, { PropType } from 'vue'
import { getUploadImageUrl, getOssUrl } from '@/api/common'
import { adminToken } from '@/libs/local-store'
import { FileListItem, ElUploadInternalFileDetail } from 'element-ui/types/upload'
import { cloneDeep } from 'lodash'
import ImageViever from 'element-ui/packages/image/src/image-viewer.vue'
export default Vue.extend({
	props: {
		/**
		 * 图片值
		 * 当count是1时是图片的url，否则是图片url的数组，为空是是空字符串或空数组
		 * 注意: 在count != 1的模式下
		 * 本组件只在created时才会根据value设置file_list数组，其余时候不会主动监听value的变化
		 * 如需从外部修改value引起file_list变化，应调用setValue()方法
		 */
		value: {
			type: [String, Array] as PropType<string | string[]>,
			default: '',
		},
		/** 图片数量，0不限 */
		count: {
			type: Number,
			default: 1,
		},
		accept: {
			type: String,
			default: 'image/*',
		},
		/** 图片和上传框的样式，通常只设置尺寸 */
		imageStyle: {
			type: Object,
			default() {
				return {
					width: 120,
					height: 120,
				}
			},
		},
		/** 图片的object-fit */
		objectFit: {
			type: String,
			default: 'cover',
		},
		/** 操作图标的尺寸 */
		iconSize: {
			type: String,
			default: '24px',
		},
	},
	components: { ImageViever },
	data() {
		return {
			getUploadImageUrl,
			adminToken,

			file_list: [] as (ElUploadInternalFileDetail | FileListItem)[],
			show_preview: false,
			preview_index: 0,
			baseUrl: '',
			oldBaseUrl: '',
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				this.setValue()
			},
		},
	},
	computed: {
		show_upload(): boolean {
			if (this.count == 0) return true
			return this.file_list.filter((it) => it.status != 'fail').length < this.count
		},
		progress_width(): number {
			return Math.min(this.imageStyle.width, this.imageStyle.height) * 0.9
		},
		preview_image_list(): string[] {
			return this.file_list
				.filter((it) => it.url)
				.map((it) => {
					let newUrl = ''
					if (it.url) {
						console.log(it.url, '----------')

						if (it.url.indexOf(this.oldBaseUrl) !== -1 || it.url.indexOf(this.baseUrl) !== -1) {
							newUrl = (it as FileListItem).url
						} else if (it.url.indexOf(this.oldBaseUrl) === -1 && it.url.indexOf(this.baseUrl) === -1) {
							newUrl = this.baseUrl + (it as FileListItem).url.replace('\\', '%5C')
						}
					}
					return newUrl
				})
		},
		preview_image_list1(): string[] {
			// (it as FileListItem).url
			return this.file_list
				.filter((it) => it.url)
				.map((it) => {
					let newUrl = ''
					if (it.url) {
						if (it.url.indexOf(this.oldBaseUrl) !== -1 || it.url.indexOf(this.baseUrl) !== -1) {
							newUrl = (it as FileListItem).url
						} else if (it.url.indexOf(this.oldBaseUrl) === -1 && it.url.indexOf(this.baseUrl) === -1) {
							newUrl = this.baseUrl + (it as FileListItem).url.replace('\\', '%5C')
						}
					}
					return newUrl
				})
		},
	},
	methods: {
		getOssUrl() {
			getOssUrl().then((res) => {
				console.log(res)
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		setValue() {
			if (this.count == 1) {
				if (this.file_list[0]?.url != this.value) this.file_list = this.value ? [{ name: '', status: 'success', url: this.value as string }] : []
			} else {
				this.file_list = (this.value as string[]).map((url) => ({ name: '', status: 'success', url }))
			}
		},
		emitInput() {
			if (this.count == 1) {
				let find = this.file_list.find((it) => it.status == 'success')
				this.$emit('input', find ? find.url : '')
			} else {
				this.$emit(
					'input',
					this.file_list.filter((it) => it.status == 'success').map((it) => it.url)
				)
			}
		},
		onSuccess(response, file) {
			if (response.code != 0) {
				const index = this.file_list.findIndex((it) => (it as any).uid == file.uid)
				this.$message.error(response.msg)
				this.$nextTick(() => {
					this.onClickDel({}, index)
					return
				})
			}
			this.$set(file, 'url', response.data?.base_url)
			this.$set(file, 'base_url', response.data?.url)
			this.emitInput() //不应该在onchange中进行emitInput 应该在onsuccess函数中进行emit
		},
		onError(response) {
			this.$message.error('图片上传错误，请重试')
		},
		onChange(file, file_list) {
			// this.file_list = file_list
			if (file.status !== 'ready') return //上传成功后在调
			// this.emitInput() //不应该在onchange中进行emitInput 应该在onsuccess函数中进行emit
			this.imgchecked(file).then((data) => {
				//data返回值即为判断结果。
				if (data) {
					this.file_list = file_list
				}
			})
		},
		//判断尺寸方法
		imgchecked(file) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader()
				reader.readAsDataURL(file.raw) // 必须用file.raw
				reader.onload = () => {
					// 让页面中的img标签的src指向读取的路径
					let img: any = new Image()
					img.src = reader.result
					console.log(reader.result)

					img.onload = () => {
						if (img.width / img.height !== 1) {
							this.$message.warning(
								`请参照格式上传图片` // `需上传图片宽高比例为1：1,当前文件${img.width}×${img.height}`
							)
							resolve(false)
						} else {
							resolve(true)
						}
					}
				}
			})
		},
		onClickMove(step, file, index) {
			let next = cloneDeep(this.file_list[index + step])
			if (!next) return

			this.$set(this.file_list, index + step, file)
			this.$set(this.file_list, index, next)
			this.emitInput()
		},
		onClickDel(file, index) {
			this.file_list.splice(index, 1)
			this.emitInput()
		},
		onClickPreview(file, index) {
			this.preview_index = index
			this.show_preview = true
		},
	},
	created() {
		this.getOssUrl()
		this.setValue()
	},
})
