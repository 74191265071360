









































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getRoomPolicyList, editRoomPolicy, delRoomPolicy } from '@/api/room-policy'
export default Vue.extend({
	data() {
		return {
			editRoomPolicy,
			...useTable({
				name: '户型政策',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
					/**标题 */
					title: undefined,
				},
				getData: (query) => getRoomPolicyList(query).then((res) => res.data),
				delData: (row) => delRoomPolicy({ id: row.id }),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
})
