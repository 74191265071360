





import Vue from 'vue'
import SelectMixin from './select-mixin'
import { getRoomLabelList } from '@/api/room-label'

/**
 * 户型标签选择器
 * 所有属性都可以从外部直接绑定到<el-select>上
 * 使用SelectMixin只需要在data中设置一个api属性，本项目标准列表数据接口返回的格式，都支持这样操作
 *
 * @event input(val) 数据变化，包括切换、选择、清空都会触发，启用多选模式后会是一个数组
 * @event load(rows,total) 加载数据完成，rows数据列，total总条数
 */

export default Vue.extend({
	mixins: [SelectMixin],
	props: {
		placeholder: {
			type: String,
			default: '请选户型标签',
		},
	},
	data() {
		return {
			api: getRoomLabelList,
		}
	},
	mounted() {
		// 使用ts时，如果用了混入，本组件中无法从this上获得混入的属性，暂不清楚解决办法，下一行打开就会报错
		// 如果需要使用，可以先在<script>上关闭ts
		// console.log(this.rows);
	},
})
