





import Vue from 'vue'
import SelectMixin from './select-mixin'

/**
 * 户型类型选择器
 *
 * @event input(val) 数据变化，包括切换、选择、清空都会触发，启用多选模式后会是一个数组
 */

export default Vue.extend({
	mixins: [SelectMixin],
	props: {
		placeholder: {
			type: String,
			default: '请选择户型类型',
		},
	},
	data() {
		return {
			dataList: [
				{ key: 1, value: '全日房' },
				{ key: 2, value: '时租房' },
			],
		}
	},
})
