import request, { ResponsePageableData } from '@/libs/request'

/** 户型标签列表 */
export const getRoomLabelList = (data?) => request<ResponsePageableData>({ url: '/adminapi/RoomLabel/getRoomLabelList', data })

/** 户型标签新增/编辑 */
export const editRoomLabel = (data?) => request<ResponsePageableData>({ url: '/adminapi/RoomLabel/editRoomLabel', data })

/** 户型标签删除 */
export const delRoomLabel = (data?) => request<ResponsePageableData>({ url: '/adminapi/RoomLabel/delRoomLabel', data })
